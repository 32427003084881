label.pdfdetail {
    float: left;
    max-width: 40%;
}
input.pdfdetail {
    float: right;
    max-width: 100%;
}

.formItem {
    max-width: 100%;
    clear: both;
    min-height: 50px;
}

.formItemSplit {
    max-width: 100%;
}

.required {
    color: #ff5050;
}

.btn-inline{
    max-height: 28px;
    background-color: #007bff;
    color: #ffffff;
    vertical-align: middle;
    padding-top: 1px;
    padding-left: .375;
    padding-right: .375
}
