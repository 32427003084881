.hist_item {
    border: 1px solid black;
}

#hist_item_head {
    background-color: #0b3454;
    color: #ef4a23;
}

#hist_item_detail {
    background-color: #ffffff;
}
