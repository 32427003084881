.privHead {
    height: 90px;
    padding: 10px;
    border: 3px;
    border-bottom-style: solid;
}

ol {
    margin: auto;
    padding: 0;
}

li {
    display: inline;
    margin: 2px;
    background-color: #ef4a23; /*amex orange*/
    line-height: 70px;
}
li a {
    text-decoration: none;
    color: #0b3454
}
li a.active {
    background-color: blue;
}
li a:hover:not(.active) {
    background-color: gray;
}

#logo {
    float: left;
    height: 70px;
}
#menu {
    float: right;
    position: relative;
    height: 70px;
 }
