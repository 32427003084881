table {
    width: 100%;
    border-collapse: collapse;
}

table, th, td {
    border: 1px solid black;
}

th {
    background-color: #ef4a23; /*amex orange*/
    color: #0b3454;
}

tr:nth-child(even) {
    background-color: #f2f2f2;
}
