.pubHead {
    height: 150px;
    text-align: center;
    padding: 10px;
}

img {
    /*width: 80%;*/
    max-height: 100%;
    /*border: 3px solid #000000;*/
}
